export const auctionContent = [
  {
    id: 1,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
  {
    id: 2,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control, 1 BHK",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
  {
    id: 3,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control, 1 BHK",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
  {
    id: 4,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control, 1 BHK",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
  {
    id: 5,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control, 1 BHK",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
  {
    id: 6,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control, 1 BHK",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
  {
    id: 7,
    title: "General Pest Control in Area colony",
    orderTime: "2 mins ago",
    userImage: "https://via.placeholder.com/150",
    username: "Manjoo Shrivastava",
    location: "Arera colony, Bhopal, 462001",
    serviceOrdered: "Pest Control, General Pest Control, 1 BHK",
    dateTime: "24th Mar, 2023 | 4-6 p.m.",
    statusBids: "37",
    status: "31 hours",
    budget: "9,000",
  },
];
