import React, { useState, useEffect } from "react";
import "./Auctions.css";
import TwoPageButton from "./TwoPageButton/TwoPageButton";
import { Box, Link, useMediaQuery } from "@mui/material";
import OnGoing from "./AuctionsContainer/OnGoingContainer/OnGoing.jsx";
import BiddedOn from "./AuctionsContainer/BiddedOnContainer/BiddedOn.jsx";
import { ArrowBackRounded, SearchRounded } from "@mui/icons-material";
import AuctionFilter from "./AuctionFilter/AuctionFilter.jsx";
import AuctionSort from "./AuctionSort/AuctionSort.jsx";
import { custAuctionOnGoingAPI } from "../../Config/apiConfig.js";
import axios from "axios";

function Auctions() {
  const [searchText, setSearchText] = useState("");
  const [currentView, setCurrentView] = useState("ongoing");
  const [acutionList, setAcutionList] = useState([]);

  // Screen Size responsive-----------
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery(" (max-width: 420px) ");

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleAuctionFilter = (data) => {
    console.log(data);
  };
  const handleAuctionSort = (data) => {
    console.log(data);
  };

  useEffect(() => {
    const fetchCustAuctionOnGoingAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.get(custAuctionOnGoingAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response.data && response.status === 200) {
            console.log("auction list", response.data);
            setAcutionList(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchCustAuctionOnGoingAPI();
  }, []);

  return (
    <div className="auctions-box">
      <div className="d-flex gap-1 gap-sm-3 align-items-start ">
        <ArrowBackRounded
          sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
          className="back-arrow mt-2 fw-bold"
        />
        <div className="">
          <h1
            style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
            className="heading fw-bold gilroy"
          >
            Auctions
          </h1>
          <p
            style={{
              fontSize: isVerySmallScreen ? "14px" : "16px",
              fontWeight: 600,
              color: "#6A7D9F",
            }}
            className="m-0 gilroy"
          >
            <Link
              underline="none"
              href={"/"}
              sx={{ textDecoration: "none", color: "#6A7D9F" }}
            >
              Home{" "}
            </Link>{" "}
            / <span style={{ color: "#1365AF" }}>Auctions</span>
          </p>
        </div>
      </div>
      <div className="auctions-contents-and-details">
        <div className="auctions-two-pages">
          <TwoPageButton
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
          <div
            className={`d-flex ${
              isExtraSmallScreen ? "flex-column" : "flex-sm-row"
            } gap-2 gap-sm-inherit justify-content-between mt-4`}
          >
            <AuctionFilter onCheck={handleAuctionFilter} />
            <AuctionSort onCheck={handleAuctionSort} />
          </div>
        </div>
        <div className="mt-0 mx-sm-2 mx-md-4 py-4 p-sm-2 py-sm-5 p-md-5 ">
          <div className=" search-bar border px-1 py-1 d-flex align-items-center justify-content-center">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                fontSize: isVerySmallScreen ? "23px" : "30px",
              }}
            />
            <Box sx={{ m: 0, width: "100%" }}>
              <input
                className="w-100 fs-6 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchText}
                onChange={handleInputChange}
              />
            </Box>
          </div>
        </div>
        <div className="auction-container px-0 px-sm-3 ">
          {currentView === "ongoing" && <OnGoing data={acutionList} />}
          {currentView === "biddedOn" && <BiddedOn />}
        </div>
      </div>
    </div>
  );
}

export default Auctions;
