import React, { useEffect, useState } from "react";
import "./BuyersProAndSer.css";
import { useMediaQuery, Link, Button } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import CustProduct from "./CustProduct/CustProduct";
import CustService from "./CustService/CustService";
import { useSelector } from "react-redux";

const BuyersProAndSer = () => {
  const [activeTab, setActiveTab] = useState("products");

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("max-width:320px ");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Get Service Id from Redux
  const popularServiceCateId = useSelector(
    (state) => state.popularCateId.popularServiceCateId
  );

  const serviceSubCateIdItem = useSelector(
    (state) => state.serviceSubCateId.serviceSubCateIdItem
  );

  useEffect(() => {
    if (popularServiceCateId || serviceSubCateIdItem) {
      setActiveTab("services");
    }
  }, [popularServiceCateId]);

  return (
    <>
      <div className="buyers-products_and_service__container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy"
            >
              Products & Services
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>Products & Services</span>
            </p>
          </div>
        </div>
        <section className="products__services___buttons mt-5 ">
          <div className="d-flex gap-sm-4 " style={{ gap: "35px" }}>
            <Button
              sx={{
                textDecoration: "none",
                fontSize: isVerySmallScreen ? "18px" : "24px",
                fontWeight: activeTab === "products" ? 600 : 400,
                color: "#1365AF",
              }}
              className="gilroy "
              onClick={() => handleTabClick("products")}
            >
              Products
            </Button>
            <Button
              sx={{
                textDecoration: "none",
                fontSize: isVerySmallScreen ? "18px" : "24px",
                fontWeight: activeTab === "services" ? 600 : 400,
                color: "#1365AF",
              }}
              className="gilroy "
              onClick={() => handleTabClick("services")}
            >
              Services
            </Button>
          </div>
          <div className="links__underline_container px-1">
            <div
              className={`underline ${
                activeTab === "services" ? "move-right" : "move-left"
              }`}
            ></div>
          </div>
        </section>
        <div>
          {activeTab === "products" ? <CustProduct /> : <CustService />}
        </div>
      </div>
    </>
  );
};

export default BuyersProAndSer;
