import React, { useState, useEffect } from "react";
import "./CustService.css";
import { SearchRounded } from "@mui/icons-material";
import { Box, Button, useMediaQuery } from "@mui/material";
import Categories from "./Categories/Categories";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import CategoriesButtons from "./CategoriesButtons/CategoriesButtons";
import ProductSort from "./ProductSort/ProductSort";
import ProductsFilter from "./ProductFilter/ProductFilter";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../Config/apiConfig";

import axios from "axios";

const CustService = () => {
  const [searchProduct, setSearchProduct] = useState("");
  const [sortService, setSortService] = useState("");
  const [custSortSerData, setCustSortSerData] = useState([]);
  const [serviceCount, setServiceCount] = useState("");
  const [cateName, setCateName] = useState("");

  // Get Service Id from Redux
  const popularServiceCateId = useSelector(
    (state) => state.popularCateId.popularServiceCateId
  );

  const serviceSubCateIdItem = useSelector(
    (state) => state.serviceSubCateId.serviceSubCateIdItem
  );

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("max-width:320px ");

  useEffect(() => {
    const handlePopularServiceId = () => {
      if (popularServiceCateId) {
        console.log("popular Id", popularServiceCateId);
        setSortService("/home/searchByCategory/" + popularServiceCateId);
      } else if (serviceSubCateIdItem) {
        setSortService("/home/searchBySubCategory/" + serviceSubCateIdItem);
      } else {
        setSortService("/customer/service/list");
      }
    };
    handlePopularServiceId();
  }, [popularServiceCateId]);

  // useEffect(() => {
  //   console.log("service sub cate Id", serviceSubCateIdItem);
  // }, [serviceSubCateIdItem]);

  const handleSearch = (event) => {
    setSearchProduct(event.target.value);
  };
  const handleProductSort = (data) => {
    if (data) {
      console.log(data);
      const sorting = data;
      setSortService(sorting);
    } else {
      setSortService("/customer/service/list");
    }
  };
  const handlePorductFilter = (data) => {
    if (data) {
      console.log(data);
      const sorting = data;
      setSortService(sorting);
    } else {
      setSortService("/customer/service/list");
    }
  };
  const handleCategoryId = (data) => {
    if (data) {
      console.log(data);
      const sorting = data;
      setSortService(sorting);
    } else {
      setSortService("/customer/service/list");
    }
  };
  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    } else {
      setCateName("");
    }
  };

  // const handlePopularServiceCateId = () => {
  //   setSortService("/home/searchByCategory/" + popularServiceCateId);
  //   console.log("Popular ID from Redux:", popularServiceCateId);
  //   handlePopularServiceCateId();
  // };

  useEffect(() => {
    const fetchServiceCategoryAPI = async () => {
      try {
        const response = await axios.get(baseUrl + sortService);
        console.log("service cate id ", response);
        if (response && response.status === 200) {
          console.log(response.data.data);
          setCustSortSerData(response.data.data);
          setServiceCount(response.data.data.length);
        }
      } catch (error) {}
    };

    fetchServiceCategoryAPI();
    // console.log("Popular ID from Redux:", popularServiceCateId);
  }, [sortService]);

  return (
    <>
      <div id="cust_service__container">
        <div className="search__bar_container d-flex mt-5 pt-3">
          <div className="search_bar search-bar border border-2 border-end-0 px-1 d-flex align-items-center justify-content-center w-100">
            <SearchRounded style={{ color: "rgba(106, 125, 159, 1)" }} />
            <Box sx={{ m: 0, width: "100%" }}>
              <input
                className="w-100 fs-6 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchProduct}
                onChange={handleSearch}
              />
            </Box>{" "}
          </div>
          <Button variant="contained" sx={{ padding: "auto 25px" }}>
            Search
          </Button>
        </div>
        <section>
          <div className="d-flex justify-content-start gap-4 mt-5">
            <div className="d-none d-md-block">
              <CategoriesButtons
                catID={handleCategoryId}
                catName={handleCateName}
              />
            </div>
            <VirticalStyleLine />
            <div className="categories_container w-100">
              <h4 className="categories_heading gilroy">
                Showing All <strong>({serviceCount})</strong>{" "}
                <span>{cateName}</span>
              </h4>
              <div
                className={`d-flex ${
                  isExtraSmallScreen ? "flex-column" : "flex-row"
                }  gap-2 gap-sm-inherit justify-content-between mt-4`}
              >
                <ProductsFilter onCheck={handlePorductFilter} />
                <ProductSort onCheck={handleProductSort} />
              </div>
              <Categories custSortSerData={custSortSerData} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustService;
