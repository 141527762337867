import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popularProductCateId: "",
};

const productCateIdSlice = createSlice({
  name: "productCateId",
  initialState,
  reducers: {
    setPopularProductCateId: (state, action) => {
      state.popularProductCateId = action.payload;
    },
  },
});

export const { setPopularProductCateId } = productCateIdSlice.actions;
export default productCateIdSlice.reducer;
