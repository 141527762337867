import React, { useState, useEffect } from "react";
import "./CustProduct.css";
import { SearchRounded } from "@mui/icons-material";
import { Box, Button, useMediaQuery } from "@mui/material";
import Categories from "./Categories/Categories";
import CategoriesButtons from "./CategoriesButton/CategoriesButtons";
import ProductFilter from "./Categories/ProductFilter/ProductFilter";
import ProductSort from "./Categories/ProductSort/ProductSort";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";
import { baseUrl } from "../../../Config/apiConfig";
import { useDispatch } from "react-redux";
import { setSearchProductItem } from "../../../redux/searchProductSlice";
import { productSearchAPI } from "../../../Config/apiConfig";
import { useSelector } from "react-redux";
import axios from "axios";

const CustProduct = () => {
  const dispatch = useDispatch();

  const [searchProduct, setSearchProduct] = useState("");
  const [productCount, setProductCount] = useState("");
  const [sortProduct, setSortProduct] = useState(
    "/customer/product/product-list"
  );
  const [custProductData, setCustProductData] = useState([]);
  const [cateName, setCateName] = useState("");

  // Screen Size for responsiveness
  // const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("(max-width:320px )");

  const handleSearchProduct = async () => {
    try {
      if (searchProduct) {
        const response = await axios.get(productSearchAPI + searchProduct);
        console.log(response.data.data.product);
        dispatch(setSearchProductItem(response.data.data.product));
      }
    } catch (error) {
      if (error && error.response.status === 404) {
        console.log("api not fount");
      }
    }
  };

  const searchProductItem = useSelector(
    (state) => state.searchProduct.searchProductItem
  );

  const handleSearch = (event) => {
    setSearchProduct(event.target.value);
  };

  const handleProductSort = (data) => {
    if (data) {
      const sorting = data;
      setSortProduct(sorting);
    }
  };
  const handlePorductFilter = (data) => {
    if (data) {
      const sorting = data;
      setSortProduct(sorting);
    }
  };
  const handleCategoryId = (data) => {
    if (data) {
      const sorting = data;
      setSortProduct("/customer/product/filterByCategory/" + sorting);
    }
  };
  const handleCateName = (data) => {
    if (data) {
      setCateName(data);
    } else {
      setCateName("");
    }
  };

  useEffect(() => {
    const fetchPorductSortAPI = async () => {
      try {
        if (searchProductItem === null) {
          const response = await axios.get(baseUrl + sortProduct);

          if (response && response.status === 200) {
            setProductCount(response.data.data.length);
            setCustProductData(response.data.data);
          }
        } else {
          setProductCount(searchProductItem.length);
          setCustProductData(searchProductItem);
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };
    fetchPorductSortAPI();
  }, [sortProduct, searchProductItem]);

  return (
    <>
      <div className="cust_product__container">
        <div className="search__bar_container d-flex mt-5 pt-3">
          <div className="search_bar search-bar border border-2 border-end-0 px-1 d-flex align-items-center justify-content-center w-100">
            <SearchRounded style={{ color: "rgba(106, 125, 159, 1)" }} />
            <Box sx={{ m: 0, width: "100%" }}>
              <input
                className="w-100 fs-6 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Product"
                value={searchProduct}
                onChange={handleSearch}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            sx={{
              padding: "auto 25px",
              borderLeft: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            onClick={handleSearchProduct}
          >
            Search
          </Button>
        </div>
        <section>
          <div className="d-flex justify-content-start gap-2 gap-lg-4 mt-5">
            <div className="d-none d-md-block">
              <CategoriesButtons
                catID={handleCategoryId}
                catName={handleCateName}
              />
            </div>
            <VirticalStyleLine className="" />
            <div className="categories_container w-100">
              <h4 className="categories_heading gilroy">
                Showing All <strong>({productCount})</strong>{" "}
                <span>{cateName}</span>
              </h4>
              <div
                className={`d-flex ${
                  isExtraSmallScreen ? "flex-column" : "flex-row"
                }  gap-2 gap-sm-inherit justify-content-between mt-4`}
              >
                <ProductFilter onCheck={handlePorductFilter} />
                <ProductSort onCheck={handleProductSort} />
              </div>
              <Categories custProductData={custProductData} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustProduct;
