import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileSetupTwo.css";
import setupImg from "./setup-img.png";
import {
  CreateRounded,
  CloudUploadOutlined,
  ArrowBackRounded,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faGlobe,
  faImage,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Button, TextField } from "@mui/material";
import { socialLinks } from "../../../Config/apiConfig";
import { loginCredentials } from "../../ZReusableData/ResuableData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ProfileSetupTwo = () => {
  // useStates
  const [file, setFile] = useState(null);
  const [brandDescription, setBrandDescription] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [xTwitterLink, setXTwitterLink] = useState("");
  const [employees, setEmployees] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  // Handling States
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleUploadClick = () => {
    // Handle the file upload logic here
    console.log("Uploading file:", file);
  };

  const handleBrandDescript = (event) => {
    setBrandDescription(event.target.value);
  };
  const handleWebsiteLink = (event) => {
    setWebsiteLink(event.target.value);
  };
  const handleFacebookLink = (event) => {
    setFacebookLink(event.target.value);
  };
  const handleInstagramLink = (event) => {
    setInstagramLink(event.target.value);
  };
  const handleXTwitter = (event) => {
    setXTwitterLink(event.target.value);
  };
  const handleEmployees = (event) => {
    setEmployees(event.target.value);
  };

  const handleFinishButton = async () => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("about_brand", brandDescription);
    formData.append("website", websiteLink);
    formData.append("facebook", facebookLink);
    formData.append("instagram", instagramLink);
    formData.append("twitter", xTwitterLink);
    formData.append("no_of_employees", employees);

    try {
      const loginData = localStorage.getItem("loginCredentials");
      if (loginData) {
        const parsedData = JSON.parse(loginData);
        setToken(parsedData.token);
        console.log(parsedData.token);

        const response = await axios.post(socialLinks, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        console.log(response);
        if (response && response.status === 200) {
          // toast.success(response.data.msg);

          navigate("/dashboard");
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="profile_setup__last d-flex justiy-content-between gap-5">
        <aside className="">
          <img src={setupImg} alt="setupImage" className="setup__img" />
        </aside>
        <section className="setup_form p-4 me-5">
          <div className="d-flex align-items-start gap-2 mb-5">
            <ArrowBackRounded className="back_arrow mt-1 fs-2 fw-bold" />
            <div>
              <h1 className="profile_heading fw-bold gilroy">
                Almost there! Just need&nbsp;
                <span style={{ color: "rgba(250, 155, 0, 1)" }}>
                  verification!
                </span>
              </h1>
            </div>
          </div>
          <div className="setup_form_container border bg-white p-5 rounded">
            <div className="upload_image ">
              <div className="d-flex gap-2 align-items-center">
                <FontAwesomeIcon
                  icon={faImage}
                  style={{ color: "#275EA7", fontSize: "19px" }}
                />
                <h4 className="labels p-0 m-0 gilroy-regular">
                  Upload your photos
                </h4>
              </div>
              <div className="file-upload-container mt-2">
                <div
                  className="file-drop-zone d-flex px-4 py-3 gap-5 align-items-center"
                  onDrop={handleFileDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="file-drop-text d-flex gap-4">
                    <CloudUploadOutlined
                      sx={{ color: "#999999", fontSize: "50px" }}
                    />
                    <div>
                      <p className="upload_para gilroy-regular">
                        Select a file or drag and drop here
                      </p>
                      <p
                        style={{ color: "#999999" }}
                        className="upload_para gilroy-regular m-0 p-0"
                      >
                        JPG, PNG or PDF, file size no more than 10MB
                      </p>
                    </div>
                  </div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="file-input"
                  />
                  <Button
                    variant="contained"
                    onClick={handleUploadClick}
                    sx={{
                      color: "#ffffff",
                      backgroundColor: "rgba(74, 123, 157, 1)",
                      padding: "10px 20px",
                      textDecoration: "none",
                    }}
                    className="gilroy-regular "
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
            <div className="about_brand mt-5">
              <div className="d-flex gap-2 align-items-center">
                <CreateRounded sx={{ color: "#275EA7" }} />
                <h4 className="labels p-0 m-0 gilroy-regular">
                  Write about the brand
                </h4>
              </div>
              <div className=" mt-2">
                <TextField
                  multiline
                  minRows={6}
                  placeholder="Start Writing here"
                  variant="outlined"
                  fullWidth
                  value={brandDescription}
                  onChange={handleBrandDescript}
                />
              </div>
            </div>
            <div className="social_links mt-5">
              <div className="d-flex gap-2 align-items-center">
                <FontAwesomeIcon
                  icon={faAt}
                  style={{ color: "#275EA7", fontSize: "19px" }}
                />
                <h4 className="labels p-0 m-0 gilroy-regular">Social Links</h4>
              </div>
              <div className="website_link d-flex align-items-center mt-2 gap-5">
                <div className="social_link_name d-flex align-items-center gap-2 me-5">
                  <FontAwesomeIcon
                    icon={faGlobe}
                    style={{ color: "#6A7D9F", fontSize: "19px" }}
                  />
                  <p
                    style={{ color: "#6A7D9F" }}
                    className="labels m-0 p-0 gilroy"
                  >
                    Website
                  </p>
                </div>
                <TextField
                  size="small"
                  placeholder="Website"
                  fullWidth
                  value={websiteLink}
                  onChange={handleWebsiteLink}
                />
              </div>
              <div className="facebook_link d-flex align-items-center mt-2 gap-5">
                <div className="social_link_name d-flex align-items-center gap-2 me-5">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ color: "#6A7d9F", fontSize: "19px" }}
                  />
                  <p
                    style={{ color: "#6A7D9F" }}
                    className="labels m-0 p-0 gilroy"
                  >
                    Facebook
                  </p>
                </div>
                <TextField
                  size="small"
                  placeholder="Facebook"
                  fullWidth
                  value={facebookLink}
                  onChange={handleFacebookLink}
                />
              </div>
              <div className="instagram_link d-flex align-items-center mt-2 gap-5">
                <div className="social_link_name d-flex align-items-center gap-2 me-5">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "#6A7d9F", fontSize: "19px" }}
                  />
                  <p
                    style={{ color: "#6A7D9F" }}
                    className="labels m-0 p-0 gilroy"
                  >
                    Instagram
                  </p>
                </div>
                <TextField
                  size="small"
                  placeholder="Instagram"
                  fullWidth
                  value={instagramLink}
                  onChange={handleInstagramLink}
                />
              </div>
              <div className="twitter_link d-flex align-items-center mt-2 gap-5">
                <div className="social_link_name d-flex align-items-center gap-2 me-5">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    style={{ color: "#6A7d9F", fontSize: "19px" }}
                  />
                  <p
                    style={{ color: "#6A7D9F" }}
                    className="labels m-0 p-0 gilroy"
                  >
                    Twitter
                  </p>
                </div>
                <TextField
                  size="small"
                  placeholder="Twitter"
                  fullWidth
                  value={xTwitterLink}
                  onChange={handleXTwitter}
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="d-flex gap-2 mb-3 align-items-center">
                <FontAwesomeIcon
                  icon={faUserGroup}
                  style={{ color: "#275EA7", fontSize: "19px" }}
                />
                <h4 className="labels p-0 m-0 gilroy-regular">
                  Number of employees
                </h4>
              </div>

              <TextField
                value={employees}
                onChange={handleEmployees}
                placeholder="Enter No. of Employees"
                size="small"
                fullWidth
              />
            </div>
          </div>
          <div className="d-flex gap-4 justify-content-center align-items-center mt-5 pt-4">
            <Button
              className="gilroy-regular"
              variant="contained"
              onClick={handleFinishButton}
              sx={{
                backgroundColor: "#1365AF",
                color: "#ffffff",
                width: "7rem",
                textTransform: "none",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "20px",
                lineHeight: "23.54px",
              }}
            >
              Finish
            </Button>
            <Button
              className="gilroy-regular"
              sx={{
                border: "2px solid #1365AF",
                color: "#1365AF",
                width: "7rem",
                textTransform: "none",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "20px",
                lineHeight: "23.54px",
              }}
            >
              Skip
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProfileSetupTwo;
