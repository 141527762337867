import React, { useEffect, useState } from "react";
import "./RequestDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { TextField, InputAdornment, useMediaQuery } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const RequestDetails = ({ name, onSave }) => {
  const [address, setAddress] = useState("");
  const [spcInstruction, setSpcInstruction] = useState("");
  const [serviceDate, setServiceDate] = useState(null);
  const [timing, setTiming] = useState(null); // Timing is independent now
  const [budget, setBudget] = useState("");
  const [auctionDuration, setAuctionDuration] = useState("");

  // Screen Size for responsiveness-----------------
  const isBet769and992 = useMediaQuery(
    "(min-width:769px)and(max-width:992px) "
  );

  const handleAddressInput = (event) => {
    setAddress(event.target.value);
  };
  const handleSpecialInstruction = (event) => {
    setSpcInstruction(event.target.value);
  };

  const handleServiceDate = (newValue) => {
    setServiceDate(newValue);
  };

  const handleTiming = (newValue) => {
    if (newValue) {
      const formattedTime = dayjs(newValue).format("HH:mm");
      setTiming(formattedTime);
    }
  };

  const handleBudgetInput = (event) => {
    setBudget(event.target.value);
  };

  const onlyAllowNumbers = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "Escape",
      "Enter",
      "ArrowLeft",
      "ArrowRight",
    ];
    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleAuctionDuration = (event) => {
    const value = event.target.value;
    if (value === "" || (value >= 1 && value <= 7)) {
      setAuctionDuration(value);
    }
  };

  useEffect(() => {
    const formData = {
      address,
      spcInstruction,
      serviceDate: serviceDate ? dayjs(serviceDate).format("YYYY-MM-DD") : null,
      timing,
      budget,
      auctionDuration,
    };
    console.log(formData);

    onSave(formData);
  }, [address, spcInstruction, serviceDate, timing, budget, auctionDuration]); // Include timing in dependencies

  const labelStyle = { color: "#343538", fontSize: "20px", fontWeight: 600 };

  return (
    <>
      <div className="request_details__container my-5 ">
        <div className="w-100 d-flex">
          <p className="request_details__filter_result gilroy p-2 px-4 rounded-pill border ">
            <FontAwesomeIcon style={{ fontSize: "14px" }} icon={faXmark} />
            &nbsp;&nbsp;{name}
          </p>
        </div>

        <div className="mt-5">
          <p className="gilroy-regular mb-2" style={labelStyle}>
            Enter your address
          </p>
          <TextField
            size="small"
            sx={{ backgroundColor: "#FFFFFF" }}
            fullWidth
            placeholder="Enter your Address"
            value={address}
            onChange={handleAddressInput}
          />
        </div>
        <div className="mt-4">
          <p className="gilroy-regular mb-2" style={labelStyle}>
            Special instructions*
          </p>
          <TextField
            size="small"
            fullWidth
            multiline
            minRows={5}
            placeholder="Text"
            sx={{ backgroundColor: "#FFFFFF" }}
            value={spcInstruction}
            onChange={handleSpecialInstruction}
          />
        </div>
        <div className="mt-4 d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between gap-4 w-100">
          <div className="w-100">
            <p className="gilroy-regular mb-2" style={labelStyle}>
              Pick a date
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="custom-date-picker"
                size="small"
                fullWidth
                value={serviceDate}
                onChange={handleServiceDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="w-100 ">
            <p className="gilroy-regular mb-2" style={labelStyle}>
              Pick time
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                className="custom-date-picker"
                size="small"
                fullWidth
                value={
                  timing
                    ? dayjs()
                        .set("hour", timing.split(":")[0])
                        .set("minute", timing.split(":")[1])
                    : null
                }
                onChange={handleTiming}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="mt-4">
          <p className="gilroy-regular mb-2" style={labelStyle}>
            Budget
          </p>
          <TextField
            size="small"
            sx={{ backgroundColor: "#FFFFFF" }}
            placeholder="Search your Address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee sx={{ width: 22, height: 22 }} />
                </InputAdornment>
              ),
            }}
            value={budget}
            onChange={handleBudgetInput}
          />
          <div className="w-100 mt-4">
            <p className="gilroy-regular mb-2" style={labelStyle}>
              How long do you want to run the auction?
            </p>
            <TextField
              size="small"
              sx={{ backgroundColor: "#FFFFFF" }}
              placeholder="2 Days"
              value={auctionDuration}
              onChange={handleAuctionDuration}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onKeyDown={onlyAllowNumbers} // Use the refactored function here
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
