import React, { useState, useRef } from "react";
import "./Registration.css";
import RegistrationForm from "./RegistrationForm/RegistrationForm.jsx";
import PersonalVerification from "./PersonalVerification/PersonalVerification.jsx";
import EmailVerification from "./EmailVerification/EmailVerification.jsx";
import UserVerification from "./UserVerification/UserVerification.jsx";
import Steps from "./Steps/Steps.jsx";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { registerPayloadAPI, credentialsAPI } from "../../Config/apiConfig.js";
import axios from "axios";
import VerticalStyleLine from "../../Components/VirticalStyleLine/VirticalStyleLine.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { isMobile, isTablet } from "react-device-detect";

function Registration() {
  const [currentStep, setCurrentStep] = useState(1);
  const [userVerificationValid, setUserVerificationValid] = useState(false);
  const [formData, setFormData] = useState({
    registrationForm: {},
    personalVerification: {},
    emailVerification: {},
    userVerification: {},
  });

  const navigate = useNavigate();

  // Screen Size for responsiveness--------------------
  const isMobileScreen = useMediaQuery("(max-width:425px)");
  const isSmallScreen = useMediaQuery("(max-width: 576px) ");
  // const registerPayload = "http://65.1.109.1/api/register/organisationDetail";
  // const credentialsApi = "http://65.1.109.1/api/register/createCredentials";

  const registrationFormRef = useRef(null);
  const personalVerificationRef = useRef(null);
  const emailVerificationRef = useRef(null);
  const userVerificationRef = useRef(null);

  // Date send to Organisation Details
  const handleApiCall = async () => {
    const { companyType, businessName, documents } = formData.registrationForm;
    const { selectDetails, cardData, dateOfBirth, nameOnPan, mobileNum } =
      formData.personalVerification;

    const dataToSend = {
      companyType,
      businessName,
      documents: JSON.stringify(documents),
      personalVerificationVia: selectDetails,
      panNo: cardData,
      panName: nameOnPan,
      panDob: dateOfBirth,
      aadhaarNo: cardData,
      aadhaarMobile: mobileNum,
    };

    if (selectDetails === "PAN") {
      const verificationData = {
        selectDetails,
        cardData,
        dateOfBirth,
        nameOnPan,
      };
      // console.log("Additional Data:", verificationData);
      localStorage.setItem(
        "verificationData",
        JSON.stringify(verificationData)
      );
    } else {
      // Clear verificationData from localStorage if selectDetails is not 'PAN'
      localStorage.removeItem("verificationData");
    }
    try {
      const response = await axios.post(registerPayloadAPI, dataToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response && response.status === 200) {
        toast.success(response.data.message);
        localStorage.setItem("apiResponseData", JSON.stringify(response.data));
      }

      return true; // Indicate success
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error);
      }
      // console.error("Error in Register payload", error);
      return false; // Indicate failure
    }
  };

  // For Next or Proceed Button
  // In Step 2 API is calling for Organisation Details
  const handleProceed = async (event) => {
    if (currentStep === 1 && registrationFormRef.current()) {
      const isValid = registrationFormRef.current();
      if (isValid) {
        setCurrentStep(2);
      }
    } else if (currentStep === 2 && personalVerificationRef.current()) {
      const isValid = personalVerificationRef.current();
      if (isValid) {
        // Proceed to API call step for Personal Verification
        const apiCallSuccess = await handleApiCall();
        if (apiCallSuccess) {
          setCurrentStep(3);
        }
      }
    } else if (currentStep === 3 && emailVerificationRef.current()) {
      const isValid = emailVerificationRef.current();
      if (isValid) {
        setCurrentStep(4);
      }
    } else if (currentStep === 4 && userVerificationRef.current) {
      const isValid = userVerificationRef.current();
      if (isValid) {
        setUserVerificationValid(true);
      }
    }

    window.scrollTo(0, 0);
  };

  // For Back Button
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
    window.scrollTo(0, 0);
  };

  const handleDataChange = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: { ...prevData[step], ...data },
    }));
  };

  // API calling for user credentials and formData submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    let parsedData = "";
    const storeData = localStorage.getItem("apiResponseData");
    if (storeData) {
      parsedData = JSON.parse(storeData);
    }
    const { firstname, lastname, username, password } =
      formData.userVerification;
    const userDataToSend = {
      id: parsedData.data._id,
      fname: firstname,
      lname: lastname,
      username,
      password,
    };
    console.log(userDataToSend);
    try {
      const response = await axios.post(credentialsAPI, userDataToSend, {
        header: {
          "Content-Type": "application/json",
        },
      });
      if (response && response.status === 200) {
        toast.success(response.data.msg);
        localStorage.setItem(
          "apiUserResponseData",
          JSON.stringify(response.data)
        );
      }

      // For handling final form submission logic
      // console.log("Form Data:", formData);
      setTimeout(() => navigate("/login"), 2000);

      return true;
    } catch (error) {
      console.error("Error in User Credentials", error);
      return false;
    }
  };

  const stepHeadings = {
    1: "Registration",
    2: "Personal Verification",
    3: "Email Verification",
    4: "User Verification",
  };

  const isProceedDisabled =
    currentStep === 1 && !formData.registrationForm.companyType;

  return (
    <>
      <div className="registration-container ">
        <form
          action="registration-form"
          className="form-registration d-flex gap-0 gap-md-4 gap-xl-5 "
          method="post"
        >
          {/* Mini Category */}
          <div className="steps-form d-none d-md-block">
            <Steps currentStep={currentStep} />
          </div>

          <VerticalStyleLine />

          <div className="form-details">
            <div className="d-flex">
              {/* Back button */}
              <div className="registration-heading-section arrow d-block  px-1">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{
                    color: "",
                    fontSize: isMobileScreen ? "20px" : "25px",
                    paddingTop: "5px",
                  }}
                  onClick={handleBack}
                />
              </div>
              <div className="verification-type-heading flex flex-column px-2">
                {/* Headings for Registration Pages  */}
                <h2
                  style={{ fontSize: isMobileScreen ? "20px" : "25px" }}
                  className="p-0 m-0 gilroy fw-bold text-dark"
                >
                  {stepHeadings[currentStep]}
                </h2>
                <p
                  style={{
                    textTransform: "none",
                    fontSize: isMobileScreen ? "12px" : "14px",
                  }}
                  className="subheading gilroy-regular p-0"
                >
                  Register as an Authorized Organization Director on Eserve
                  Market to Manage Verified Services
                </p>
              </div>
            </div>

            <div className="registration_form_container mt-2 mt-lg-1 gilroy">
              <div className="registration-form-section p-0 mt-3 mt-sm-4 mt-md-5 ">
                <div
                  style={{ backgroundColor: "rgb(255,255,255)" }}
                  className="registration-form border rounded ps-0 p-1 p-sm-4"
                >
                  {currentStep === 1 && (
                    <RegistrationForm
                      onDataChange={(data) =>
                        handleDataChange("registrationForm", data)
                      }
                      onValidate={(validateFunc) =>
                        (registrationFormRef.current = validateFunc)
                      }
                    />
                  )}
                  {currentStep === 2 && (
                    <PersonalVerification
                      onDataChange={(data) =>
                        handleDataChange("personalVerification", data)
                      }
                      onValidate={(validateFunc) =>
                        (personalVerificationRef.current = validateFunc)
                      }
                    />
                  )}
                  {currentStep === 3 && (
                    <EmailVerification
                      onDataChange={(data) =>
                        handleDataChange("emailVerification", data)
                      }
                      onValidate={(validateFunc) =>
                        (emailVerificationRef.current = validateFunc)
                      }
                    />
                  )}
                  {currentStep === 4 && (
                    <UserVerification
                      onDataChange={(data) =>
                        handleDataChange("userVerification", data)
                      }
                      onValidate={(isValid) =>
                        setUserVerificationValid(isValid)
                      }
                      ref={(ref) => (userVerificationRef.current = ref)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="proceed-register-button-container d-flex flex-column justify-content-center align-items-center mt-5 ms-0 ms-sm-5">
              {currentStep !== 4 ? (
                <Button
                  id="proceedBtn"
                  type="button"
                  onClick={handleProceed}
                  disabled={isProceedDisabled}
                  style={{
                    backgroundColor: "rgba(19, 101, 175, 1)",
                    color: "#fff",
                    borderRadius: "4px",
                    padding: isMobileScreen ? "0.4rem 1.5rem" : "0.75rem 2rem",
                    cursor: isProceedDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  Proceed
                </Button>
              ) : (
                <Button
                  id="registerBtn"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!userVerificationValid}
                  style={{
                    backgroundColor: "rgba(19, 101, 175, 1)",
                    color: "#fff",
                    borderRadius: "4px",
                    padding: isMobileScreen ? "0.4rem 1.5rem" : "0.75rem 2rem",
                    cursor: !userVerificationValid ? "not-allowed" : "pointer",
                  }}
                >
                  Register
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Registration;
