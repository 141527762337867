import React, { useEffect, useState } from "react";
import "./AuctionDetails.css";
import DetailsAndBidsBtn from "./DetailsAndBidsBtn";
import Details from "./Details/Details";
import Bids from "./Bids/Bids";
import Headings from "../../Headings/Headings";
import { useSelector } from "react-redux";
import { custAuctionDetailsAPI } from "../../../Config/apiConfig";
import axios from "axios";

function AuctionDetails() {
  const [isActive, setIsActive] = useState(true);
  const [auctionDetails, setAuctionDetails] = useState([]);

  const [auctionDetailsBids, setAuctionDetailsBids] =
    useState("/ Auctions Details");

  const handleButtonClick = (isDetailsActive) => {
    setIsActive(isDetailsActive);
    setAuctionDetailsBids(
      isDetailsActive ? "/ Auctions Details" : "/ Auction Bids"
    );
  };

  const custAuctionCateId = useSelector(
    (state) => state.custAuctionId.custAuctionCateId
  );
  useEffect(() => {
    console.log("cust id", custAuctionCateId);
    if (custAuctionCateId) {
      const fetchCustAuctionDetailsAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          try {
            const response = await axios.get(
              custAuctionDetailsAPI + custAuctionCateId,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${parsedData.token}`,
                },
              }
            );
            if (response && response.status === 200) {
              console.log("auction detais", response.data.data);
              localStorage.setItem(
                "custAuctionDetails",
                JSON.stringify(response.data.data)
              );
              setAuctionDetails(response.data.data);
            }
          } catch (error) {}
        }
      };
      fetchCustAuctionDetailsAPI();
    }
  }, [custAuctionCateId]);

  return (
    <>
      <div className="auction-details-box gilroy">
        <Headings headings="Auctions" auctionDetailsBids={auctionDetailsBids} />
        <div>
          <DetailsAndBidsBtn
            isActive={isActive}
            onButtonClick={handleButtonClick}
          />
        </div>
        <div>{isActive ? <Details data={auctionDetails} /> : <Bids />}</div>
      </div>
    </>
  );
}

export default AuctionDetails;
