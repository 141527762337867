import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./OnGoing.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, AvatarGroup, Avatar, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { setCustAuctionCateId } from "../../../../redux/custAuctionIdSlice";

const itemsPerPage = 4;

function OnGoing({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePlaceBid = (id) => {
    console.log("place bid id", id);
    const auctionId = id;
    dispatch(setCustAuctionCateId(auctionId));
    navigate("/auctionDetails");
  };

  const [currentPage, setCurrentPage] = useState(0);

  // Screen size for responsiveness--------------------
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");
  const isMediumScreen = useMediaQuery(" (max-width: 876px) ");
  const isSmallScreen = useMediaQuery("(max-width:564px) ");
  const isVerySmallScreen = useMediaQuery(" (max-width:420px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:350px)");

  const auctionInfo =
    "d-flex flex-column gap-2 gap-sm-3 gap-md-2 gap-lg-3 w-100 justify-content-between align-items-start mt-3 my-sm-3 my-md-2 my-lg-3 mx-2 w-50";

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = data.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div
              key={item._id}
              className="p-2 py-3 ps-md-4 py-md-4 pe-0 border rounded my-4"
              onClick={() => handlePlaceBid(item._id)}
            >
              <div className="d-flex flex-column flex-md-row align-items-center ms-sm-0 ">
                <div
                  className={`d-flex justify-content-center ps-2
                      pe-0 `}
                >
                  <img
                    src={item.serviceImage}
                    alt={item.title}
                    style={{
                      //  isMediumScreen ? "8.8rem" :
                      width: isMediumScreen ? "8.8rem" : "10rem",
                      height: isMediumScreen ? "8.8rem" : "10rem",
                    }}
                    className="ps-md-0"
                  />
                </div>

                <div className=" w-100 mt-3 mt-md-0 pt-3">
                  <Row className="w-100 ps-2 ps-md-1 ">
                    <Col sm={12}>
                      <div className="d-flex flex-column flex-sm-row  justify-content-between align-items-start align-items-sm-center w-100 ">
                        <h4 className="content-title ms-2 gilroy p-0">
                          {item.serviceName}
                        </h4>
                        <div
                          style={{ width: isSmallScreen ? "100%" : "auto" }}
                          className="d-flex justify-content-between justify-content-md-end ps-2 pe-0"
                        >
                          <span
                            className="text-lowercase mt-1"
                            style={{
                              color: "rgba(106, 125, 159, 1)",
                              fontSize: isLargeScreen ? "12px" : "14px",
                            }}
                          >
                            {item.createdTime}
                          </span>
                          <MoreVertIcon
                            style={{
                              fontSize: isSmallScreen ? "30px" : "35px",
                              color: "#000",
                            }}
                            className=""
                          />
                        </div>
                      </div>
                      <div
                        className={`d-flex ${
                          isVerySmallScreen ? "flex-column" : "flex-row"
                        } gap-3 mt-2  mx-sm-2`}
                      >
                        <p
                          size="small"
                          className="filter-point m-0 rounded gilroy"
                        >
                          General Pest Control
                        </p>
                        <p
                          size="small"
                          className="filter-point rounded gilroy m-0"
                        >
                          Pest Control
                        </p>
                      </div>
                    </Col>
                    <Col sm={12} md={6} className="">
                      <div className={auctionInfo}>
                        <div className="avatar--location d-flex gap-2 align-items-center">
                          <Avatar
                            sx={{ width: 18, height: 18 }}
                            src={item.userImage}
                          />
                          <p className=" details gilroy m-0 ">{item.cName}</p>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <InfoIcon
                            className="icons-color"
                            sx={{ width: 18, height: 18 }}
                          />
                          <p className="details info gilroy m-0 p-0">
                            {item.instruction.length > 30
                              ? `${item.instruction.substring(0, 30)}...`
                              : item.instruction}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} className="">
                      <div className={auctionInfo}>
                        <div className="d-flex gap-2 align-items-center ">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{ color: "#6a7d9f", fontSize: "18px" }}
                          />

                          <p className="details gilroy m-0 p-0 ">
                            {item.address}
                          </p>
                        </div>
                        <div className="d-flex gap-2 align-items-center ">
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            style={{ color: "#6a7d9f", fontSize: "17px" }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.date}&nbsp;|&nbsp;{item.time}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="ps-3 ps-md-0 ms-0 mt-2">
                <div className="d-flex flex-column flex-md-row  justify-content-between align-items-start align-items-md-center align-item-md-center pe-3">
                  <div>
                    <div className="d-flex align-items-center gap-3 mt-2">
                      <div className="avatars_container d-flex">
                        <AvatarGroup total={2}>
                          {item.totalBid.map((bid) => (
                            <Avatar
                              key={bid._id}
                              alt="Remy Sharp"
                              src="/static/images/avatar/1.jpg"
                              sx={{
                                width: isExtraSmallScreen ? 16 : 20,
                                height: isExtraSmallScreen ? 16 : 20,
                              }}
                            />
                          ))}
                        </AvatarGroup>
                      </div>
                      <div className="d-flex gap-2">
                        <div className="bids-box px-2 px-sm-3 py-2 rounded-pill">
                          <p className="bids m-0">
                            <span className="fw-bold">
                              {item.totalBid.length}
                            </span>
                            &nbsp; Bids
                          </p>
                        </div>
                        <div className="timer px-2 px-sm-3 py-2 rounded-pill">
                          <p className="hours-left m-0">{item.remainingTime}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    sm={6}
                    className={`d-flex justify-content-end ${
                      isExtraSmallScreen ? "w-100" : "w-auto"
                    } `}
                  >
                    <div
                      className={`d-flex ${
                        isExtraSmallScreen
                          ? "flex-column w-100 gap-3 justify-content-between"
                          : "flex-row w-auto justify-content-start"
                      } align-items-center gap-1 gap-sm-3 gap-md-2 gap-lg-3 mt-2 align-items-center pe-2`}
                    >
                      <p
                        className={`budget-price gilroy text-center m-0 ${
                          isExtraSmallScreen ? "w-100" : "w-auto"
                        } `}
                      >
                        Budget: <span>&#x20B9;{item.budget}</span>
                      </p>
                      <Box
                        className={`${isExtraSmallScreen ? "w-100" : "w-auto"}`}
                      >
                        <Button
                          style={{
                            backgroundColor: "rgba(19, 101, 175, 1)",
                            color: "rgba(255,255,255,1)",
                            padding: "3px 25px",
                            fontSize: isSmallScreen
                              ? "13px"
                              : isLargeScreen
                              ? "15px"
                              : "18px",
                          }}
                          fullWidth={isExtraSmallScreen}
                          size="small"
                          className="place-bid-button text-capitalize"
                        >
                          Place a Bid
                        </Button>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          // <ChevronBack color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          // <ChevronForward color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default OnGoing;
