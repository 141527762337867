import React, { useState } from "react";
import "./ProductCart.css";
import { ArrowBackRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Link,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import OrderSummary from "./OrderSummary/OrderSummary";
import FinancialDetails from "./FinancialDetails/FinancialDetails";
import ProductVerify from "./ProductVerify/ProductVerify";

const steps = ["Order Summary", "Financial Details", "Verify"];
const ProductCart = () => {
  // Screen Size for responsiveness
  const isVerySmallScreen = useMediaQuery("(max-width:568px) ");

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scroll(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scroll(0, 0);
  };

  return (
    <>
      <div className="product_cart__container">
        <div className="d-flex gap-2 gap-sm-3 align-items-start">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-2 fw-bold"
            onClick={handleBack}
          />
          <div className="mb-1">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold m-0  gilroy"
            >
              Cart
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home /{" "}
              </Link>
              <Link
                underline="none"
                href={"/cust-product-service"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Products & Services
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}> Cart </span>
            </p>
          </div>
        </div>

        <section className="product_cart__steps ">
          <Box
            sx={{ width: "100%", padding: isVerySmallScreen ? "0" : "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  width: isVerySmallScreen ? "100%" : "55%",
                }}
              >
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <React.Fragment>
                {activeStep === 0 && <OrderSummary />}
                {activeStep === 1 && <FinancialDetails />}
                {activeStep === 2 && <ProductVerify />}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    pt: 5,
                  }}
                >
                  {/* <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button> */}

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      textTransform: "none",
                      fontSize: isVerySmallScreen ? "16px" : "20px",
                      fontWeight: "500",
                      color: "#ffffff",
                    }}
                    className="gilroy"
                  >
                    {activeStep === steps.length - 1 ? "Verify" : "Continue"}
                  </Button>
                </Box>
              </React.Fragment>
            </Box>
          </Box>
        </section>
      </div>
    </>
  );
};

export default ProductCart;
