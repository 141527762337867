import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  Button,
  Modal,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { custRegistrationAPIs } from "../../../../Config/apiConfig";
import { custOTPVerificationAPI } from "../../../../Config/apiConfig";
import { custResendOtpAPI } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegistrationPage = ({ open, handleClose, style }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [registeredId, setRegisteredId] = useState("");
  const [otp, setOtp] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleEmailId = (event) => {
    setEmailId(event.target.value);
  };
  const handleOtp = (event) => {
    setOtp(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };
  const handleTermsAcceptedChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const handleCustRegistrationAPI = async () => {
    try {
      const formData = {
        fname: firstName,
        lname: lastName,
        email: emailId,
      };
      if (formData) {
        const response = await axios.post(custRegistrationAPIs, formData, {
          header: {
            "Content-Type": "application/json",
          },
        });
        if (response && response.status === 200) {
          // console.log(response.data.data._id);
          setRegisteredId(response.data.data._id);
          toast.success(response.data.msg);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const otpVerifiedMsg = error.response.data.msg;
        toast.error(otpVerifiedMsg);
      } else if (error.response && error.response.status === 409) {
        const otpVerifiedMsg = error.response.data.msg;
        toast.warn(otpVerifiedMsg);
      }
    }
  };

  const handleCustOtpVerify = async () => {
    try {
      if (termsAccepted) {
        const response = await axios.post(custOTPVerificationAPI, {
          id: registeredId,
          otp: otp,
        });
        if (response.status === 200) {
          const otpVerifiedMsg = response.data.msg;

          toast.success(otpVerifiedMsg);
          setIsRegister(true);
          setFirstName("");
          setLastName("");
          setEmailId("");
          setOtp("");
          handleClose();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const otpVerifiedMsg = error.response.data.msg;
        toast.error(otpVerifiedMsg);
      } else if (error.response && error.response.status === 409) {
        const otpVerifiedMsg = error.response.data.msg;
        setOtp("");
        toast.warn(otpVerifiedMsg);
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.get(custResendOtpAPI + registeredId);
      if (response && response.status === 200) {
        // console.log(response);
        toast.success(response.data.message);
      }
    } catch (error) {}
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} mx className="no-scrollbar">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-3">
            <CloseIcon
              onClick={handleClose}
              fontSize="small"
              color="rgba(26, 27, 30, 1)"
            />
            <p
              style={{
                fontFamily: "Gilroy-SemiBold",
                fontSize: "28px",
                color: "rgba(19, 101, 175, 1)",
              }}
              className="register_to_continue m-0"
            >
              Register &nbsp;
              <span style={{ color: "rgba(0, 0, 0, 1)" }}>to continue!</span>
            </p>
          </div>
          <div className="registration_page_form mt-3">
            <div
              style={{
                gap: "29px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "rgba(26, 27, 30, 1)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                }}
              >
                First Name
                <Form.Control
                  sx={{ color: "#6A7D9F" }}
                  className="gilroy-regular"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={handleFirstName}
                />
              </div>
              <div
                style={{
                  color: "rgba(26, 27, 30, 1)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                }}
              >
                Last Name
                <Form.Control
                  sx={{ color: "#6A7D9F" }}
                  className="gilroy-regular"
                  placeholder="Enter Lastt Name"
                  value={lastName}
                  onChange={handleLastName}
                />
              </div>
              <div
                style={{
                  color: "rgba(26, 27, 30, 1)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  gap: "4px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                Email ID
                <Form.Control
                  sx={{ color: "#6A7D9F" }}
                  className="gilroy-regular"
                  placeholder="Enter Email Id"
                  value={emailId}
                  onChange={handleEmailId}
                />
                <Button
                  sx={{
                    px: {
                      lg: "12px",
                      md: "1px",
                    },
                    marginTop: "10px",
                  }}
                  style={{ alignSelf: "flex-start" }}
                  onClick={handleCustRegistrationAPI}
                  className="nav-icon-btn2 gilroy"
                >
                  Get OTP
                </Button>
              </div>

              <div
                style={{
                  color: "rgba(26, 27, 30, 1)",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                OTP
                <Form.Control
                  sx={{ color: "#6A7D9F" }}
                  className="gilroy-regular"
                  value={otp}
                  onChange={handleOtp}
                />
                <div
                  style={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: "15px",
                    color: "rgba(128, 128, 127, 1)",
                    display: "flex",
                    alignSelf: "flex-end",
                  }}
                >
                  Didn’t receive code?&nbsp;
                  <span
                    style={{ color: "rgba(19, 101, 175, 1)" }}
                    onClick={handleResendOtp}
                  >
                    Resend
                  </span>
                </div>
              </div>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                  }
                  label="Remember me"
                  style={{ fontFamily: "Gilroy-Regular" }}
                />
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      size="small"
                      checked={termsAccepted}
                      onChange={handleTermsAcceptedChange}
                    />
                  }
                  label="I agree to all the Terms and Privacy policy"
                  style={{ fontFamily: "Gilroy-Regular" }}
                />
              </FormGroup>
            </div>
            <div
              style={{
                // border: "1px solid red",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  px: {
                    lg: "12px",
                    md: "1px",
                  },
                  backgroundColor: "rgba(19, 101, 175, 1)",
                  "&:hover": {
                    backgroundColor: "rgba(19, 101, 175, 1)",
                  },
                  fontSize: "16px",
                  color: "#ffffff",
                  textTransform: "capitalize",
                  textAlign: "center",
                  fontFamily: "Gilroy-SemiBold",
                  fontStyle: "normal",
                  lineHeight: "20px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
                onClick={handleCustOtpVerify}
              >
                Register
              </Button>

              <Button
                sx={{
                  px: {
                    lg: "12px",
                    md: "1px",
                  },
                }}
                startIcon={
                  <img
                    src="images/logo/google.png"
                    style={{ width: "14px", height: "14px" }}
                    alt=""
                  />
                }
                style={{
                  border: " 2px solid rgba(19, 101, 175, 1)",
                  fontSize: "16px",
                  color: "rgba(19, 101, 175, 1)",
                  textTransform: "capitalize",
                  textAlign: "center",
                  fontFamily: "Gilroy-SemiBold",
                  fontStyle: "normal",
                  lineHeight: "20px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  width: "252px",
                }}
              >
                Sign-in with google
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RegistrationPage;
