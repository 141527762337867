import React, { useEffect, useState } from "react";
import "./PickService.css";
import { TextField, MenuItem, Select } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { custBidServicesAPI } from "../../../Config/apiConfig";
import axios from "axios";

const PickService = ({ data, details, onSave }) => {
  const [categoryName, setCategoryName] = useState("");
  const [childCategoryId, setChildCategoryId] = useState("");
  const [childCategoryName, setChildCategoryName] = useState("");
  const [cateDetails, setCateDetails] = useState([]);
  const [menuOption, setMenuOption] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceDetails, setServiceDetails] = useState("");

  useEffect(() => {
    if (data && details) {
      console.log(data);
      console.log("details data", details);
      setCategoryName(data);
      setCateDetails(details);
    }
  }, [data, details]);

  const handleService = (id, name) => {
    console.log("select id", id, name);
    const subcategoryId = id;
    const subcategoryName = name;
    setChildCategoryName(subcategoryName);
    setChildCategoryId(subcategoryId);
  };

  useEffect(() => {
    const fetchCustBidServicesAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData && parsedData.token && childCategoryId) {
        try {
          const response = await axios.get(
            custBidServicesAPI + childCategoryId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${parsedData.token}`,
              },
            }
          );
          if (response && response.status === 200) {
            console.log("Options are", childCategoryName, response);
            setMenuOption(response.data.data);
          }
        } catch (error) {}
      }
    };
    fetchCustBidServicesAPI();
  }, [childCategoryId]);

  const handleMenuItem = (id, name, details) => {
    console.log("Option ID:", id, "Option Title:", name);
    if (id && name) {
      const serviceID = id;
      const serviceName = name;
      const service = details;
      console.log(details);
      setServiceId(serviceID);
      setServiceName(serviceName);
      setServiceDetails(service);
    }
  };

  useEffect(() => {
    const formData = {
      childCategoryId,
      childCategoryName,
      serviceId,
      serviceName,
      serviceDetails,
    };
    onSave(formData);
  }, [serviceId, serviceName, serviceDetails, onSave]);

  return (
    <>
      <div className="search_pick__service mt-4 mb-5  w-100">
        <div className="d-flex align-items-center border px-3 m-5 mt-0 ">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: "#6a7d9f", fontSize: "20px" }}
          />
          <TextField
            sx={{
              border: "none",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            fullWidth
            size="small"
          />
        </div>
        <div className="w-100">
          <h3 className="heading_service gilroy my-3">{categoryName} </h3>
          <Row>
            {Array.isArray(cateDetails) &&
              cateDetails.map((item) => (
                <Col
                  key={item._id}
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  lg={6}
                  className="p-1"
                >
                  <Select
                    value=""
                    fullWidth
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ background: "#EEEEF4" }}
                    onOpen={() => handleService(item._id, item.name)} // Logs the id when the Select opens
                  >
                    <MenuItem value="" sx={{ color: "#e3e3e3" }}>
                      {item.name}
                    </MenuItem>

                    {menuOption &&
                      menuOption.map((option) => (
                        <MenuItem
                          key={option._id}
                          value={option._id}
                          onClick={() =>
                            handleMenuItem(
                              option._id,
                              option.title,
                              option.details
                            )
                          }
                        >
                          {option.title}
                        </MenuItem>
                      ))}
                  </Select>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default PickService;
