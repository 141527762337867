import React, { useState } from "react";
import "./FinancialDetails.css";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  useMediaQuery,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
  styled,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
const FinancialDetails = () => {
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentAuthority, setPaymentAuthority] = useState("");
  const [cdaCode, setCdaCode] = useState("");
  const [codeHead, setCodeHead] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  // Screen Size for responsiveness
  const isLargeScreen = useMediaQuery("(max-width: 992px)");
  const isMinSmallScreen = useMediaQuery("(min-width: 568px) ");

  const handleRadioChange = (event) => {
    if (event.target.value === "unit") {
      setOpen(true);
    }
    setPaymentMode(event.target.value);
    console.log(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentAuthority = (event) => {
    setPaymentAuthority(event.target.value);
  };

  const handleCDACode = (event) => {
    setCdaCode(event.target.value);
  };

  const handleCodeHead = (event) => {
    setCodeHead(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
    const validFiles = [];
    let hasError = false;

    files.forEach((file) => {
      if (file.size > maxFileSize) {
        hasError = true;
        setError(`File "${file.name}" exceeds 2MB size limit.`);
      } else {
        validFiles.push(file);
      }
    });

    if (!hasError) {
      setFileNames(validFiles.map((file) => file.name)); // Store valid file names
      setError(""); // Clear any previous errors if all files are valid

      // Create an array to store base64 strings of valid files
      const base64Files = [];

      validFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const base64 = e.target.result;
          base64Files.push({ name: file.name, base64 }); // Push the file base64 string and name as an object

          // Once all files are processed, store them in localStorage under 'financialApproval'
          if (base64Files.length === validFiles.length) {
            localStorage.setItem(
              "financialApproval",
              JSON.stringify(base64Files)
            );
            console.log(
              "Files saved in localStorage as financialApproval:",
              base64Files
            );
          }
        };
        reader.readAsDataURL(file); // Read the file as a data URL (base64 string)
      });
    }
  };

  const formControlStyle =
    "d-flex flex-column flex-sm-row gap-1 gap-sm-5 mt-4 align-items-sm-center";
  const styleLabel = {
    fontSize: isLargeScreen ? "14px" : "16px",
    fontWeight: 500,
    width: isMinSmallScreen ? "70%" : "100%",
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <div className="financial_details__container">
        <div className="border rounded p-3">
          <FormControl className="w-100 mb-4">
            <div className={`${formControlStyle}`}>
              <label style={styleLabel} htmlFor="payment" className="gilroy">
                Payment By CDA/Unit*
              </label>
              <RadioGroup
                row
                aria-labelledby="financial-details"
                name="paymentMethod"
                id="payment"
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="cda"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      CDA
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="unit"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Unit
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </FormControl>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Payment Mode Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Selection of this method is applicable to only Post Audit bills
                wherein payment will be made bby the Unit not CDA. Once demand
                created with selected payment mode, cannot be revoked.
              </DialogContentText>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                >
                  Okay
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

          <Grid container>
            <Grid item xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Select Payment Authority*
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mb-5">
              <Select
                id="demo-simple-select"
                value={paymentAuthority}
                fullWidth
                size="small"
                onChange={handlePaymentAuthority}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Designation of official providing administration approval*
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mb-5">
              <TextField fullWidth size="small" />
            </Grid>
            <Grid xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Designation of official providing financial approval*
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="">
              <TextField fullWidth size="small" />
            </Grid>
          </Grid>
          <FormControl className="w-100 my-4">
            <div
              className={`d-flex flex-column flex-sm-row gap-1 gap-sm-5 align-items-sm-center`}
            >
              <label style={styleLabel} htmlFor="payment" className="gilroy">
                IFD Concurrence / Component Authority (HOD/ Head of Office)
                Approval Required?
              </label>
              <RadioGroup
                row
                aria-labelledby="financial-details"
                name="ifdApproval"
                id="ifdApproval"
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      Yes
                    </p>
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" />}
                  label={
                    <p style={styleLabel} className="m-0">
                      No
                    </p>
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          </FormControl>

          <Grid container>
            <Grid item xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                CDA Code
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mb-5">
              <Select
                id="demo-simple-select"
                value={cdaCode}
                fullWidth
                size="small"
                onChange={handleCDACode}
              >
                <MenuItem value="">Please Select CDA code</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Select Code head*
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mb-5">
              <Select
                id="demo-simple-select"
                value={codeHead}
                fullWidth
                size="small"
                onChange={handleCodeHead}
              >
                <MenuItem value="">Please Select CDA head</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Unit Code
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mb-5">
              <TextField fullWidth size="small" />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Amount To Be Paid
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="mb-5">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#000000",
                }}
                className="m-0 gilroy"
              >
                &#8377; 17,000
              </p>
            </Grid>
            <Grid item xs={12} sm={4} md={7}>
              <p
                style={styleLabel}
                className="form_title gilroy m-0 pb-1 gilroy"
              >
                Financial Approval*
              </p>
            </Grid>
            <Grid item xs={12} sm={7} md={5} className="mb-5">
              <div className="d-flex flex-column flex-sm-row justify-content-start align-items-start">
                <Button
                  sx={{ width: "8rem" }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={
                    <CloudUpload
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  }
                  className="gilroy"
                  size="small"
                >
                  Choose files
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf" // Only accept PDF files
                    onChange={handleFileChange} // Handle file selection
                    multiple
                  />
                </Button>
                {/* Show file names next to the button */}
                {fileNames.length > 0 && (
                  <div style={{ marginLeft: "1rem" }}>
                    {fileNames.join(", ")}
                  </div>
                )}
                {/* Show error message if any file exceeds 2MB */}
                {error && (
                  <div style={{ color: "red", marginTop: "0.5rem" }}>
                    {error}
                  </div>
                )}
              </div>
              <FormHelperText className="p-0 m-0 ">
                Please Upload file in pdf format with no exceeding 2MB
              </FormHelperText>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default FinancialDetails;
