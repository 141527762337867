import React, { useState, useEffect } from "react";
import "./Home.css";
import Carousel from "./Carousel/Carousel";
import Popular from "./Services/Popular/Popular";
import PropertyRent from "./Services/PropertyRent/PropertyRent";
import Business from "./Services/Business/Business";
import Automobile from "./Services/Automobile/Automobile";
import Explore from "./Explore/Explore";
import CustomerSay from "./CustomerSay/CustomerSay";
import Counter from "./Counter/Counter";
import Newslater from "./Newslater/Newslater";
import Download from "./Download/Download";
import HealthWellness from "./Services/HealthWellness/HealthWellness";
import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Products from "./Services/Products/Products";
import { homeSlidersAPI } from "../../Config/apiConfig";
import { productSearchAPI } from "../../Config/apiConfig";
import { useDispatch } from "react-redux";
import { setSearchProductItem } from "../../redux/searchProductSlice";
import { setSeeMoreServicesItem } from "../../redux/seeMoreServiceSlice";
import { custSeeServicesListAPI } from "../../Config/apiConfig";
import { SearchRounded } from "@mui/icons-material";
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [homeSlider, setHomeSlider] = useState({});
  const [searchProduct, setSearchProduct] = useState("");

  // const [popularSlider , setPopularSlider] = useState({})

  const isExtraSmallScreen = useMediaQuery("(max-width:425px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 568px) ");
  const isSmallScreen = useMediaQuery("(max-width:671px)");

  const handleHomeSeeMoreProducts = async () => {
    try {
      const response = await axios.get(custSeeServicesListAPI);
      if (response && response.status === 200) {
        // console.log("See More Data", response.data);
        dispatch(setSeeMoreServicesItem(response.data));
        navigate("/services-lists");
        window.scroll(0, 0);
      }
    } catch (error) {}
  };
  const handleSearchProduct = (event) => {
    setSearchProduct(event.target.value);
  };

  const handleSearchProductBtn = async () => {
    try {
      if (searchProduct) {
        const response = await axios.get(productSearchAPI + searchProduct);
        // console.log(response);
        dispatch(setSearchProductItem(response.data.data.product));
        navigate("/cust-product-service");
        window.scroll(0, 0);
      }
    } catch (error) {
      if (error && error.response.status === 404) {
        console.log("api not fount");
      }
    }
  };

  useEffect(() => {
    const fetchHomeSliderApi = async () => {
      try {
        const response = await axios.get(homeSlidersAPI);
        if (response.status === 200) {
          const sliderContent = response.data.data;

          // console.log("slider", response.data.data);

          setHomeSlider(sliderContent);
          // console.log("sliderContent", sliderContent.category);
        }
      } catch (error) {
        console.log("error in api ", error);
      }
    };
    fetchHomeSliderApi();
  }, []);

  window.scroll(0, 0);

  const design = (
    <>
      <Carousel data={homeSlider} />
      <div id="sliders_container" className="mt-5 ">
        <div className="search__bar_container d-flex justify-content-center w-100 my-5 py-3">
          <div className="search_bar search-bar border border-2 border-end-0 px-1 d-flex align-items-center justify-content-center">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                width: isVerySmallScreen ? "16px" : "25px",
                height: isVerySmallScreen ? "16px" : "25px",
              }}
            />
            <Box
              sx={{
                m: 0,
                width: "100%",
                height: isVerySmallScreen ? "30px" : "auto",
              }}
            >
              <input
                className="w-100 fs-6 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Product"
                value={searchProduct}
                onChange={handleSearchProduct}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            sx={{
              padding: isVerySmallScreen ? "0" : "auto 25px",
              fontSize: isVerySmallScreen ? "12px" : "18px",
              borderLeft: "none",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            className="gilroy"
            onClick={handleSearchProductBtn}
          >
            Search
          </Button>
        </div>

        <Popular data={homeSlider} />
        <Products data={homeSlider} />
        <Business data={homeSlider} />
        <Automobile data={homeSlider} />
        <PropertyRent data={homeSlider} />
        <HealthWellness data={homeSlider} />
        {/* Slider -------------- */}

        <div
          style={{
            display: "flex",
            padding: "6px 16px",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "60px",
          }}
        >
          <Button
            variant="contained"
            className="gilroy-regular"
            sx={{
              fontWeight: "bold",
              background: "#D0DFFF",
              borderRadius: "4px",
              color: "#001E2F",
            }}
            onClick={handleHomeSeeMoreProducts}
          >
            See More
          </Button>
        </div>
        <Explore />
        <CustomerSay />
        <Counter />
      </div>
      <Newslater />
      <Download />
    </>
  );
  return design;
};

export default Home;
