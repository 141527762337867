import { configureStore } from "@reduxjs/toolkit";
import popularCateIdReducer from "./popularCateIdSlice";
import productCateIdReducer from "./productCateIdSlice";
import searchProductReducer from "./searchProductSlice";
import serviceSubCateIdReducer from "./serviceSubCateIdSlice";
import seeMoreServiceReducer from "./seeMoreServiceSlice";
import custAuctionIdReducer from "./custAuctionIdSlice";

export const store = configureStore({
  reducer: {
    popularCateId: popularCateIdReducer,
    productCateId: productCateIdReducer,
    searchProduct: searchProductReducer,
    serviceSubCateId: serviceSubCateIdReducer,
    seeMoreService: seeMoreServiceReducer,
    custAuctionId: custAuctionIdReducer,
  },
});
