import React, { useState, useRef } from "react";
import "./DegitalSign.css";
import { ArrowForwardIosRounded, CloudUpload } from "@mui/icons-material";
import SignatureCanvas from "react-signature-canvas";
import { Button, MenuItem, Select, styled } from "@mui/material";
import { CompactPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrush } from "@fortawesome/free-solid-svg-icons";

function DegitalSign() {
  const [selectedColor, setSelectedColor] = useState("#000");
  const [selectBackgroundColor, setSelectBackgroundColor] = useState("#ffffff");
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] =
    useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [brushSize, setBrushSize] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null); // Track uploaded file
  const sigCanvas = useRef(null);

  // Brush Color Size handling
  const handleSelectColor = (color) => {
    setSelectedColor(color.hex);
    setShowColorPicker(false);
  };

  // Brush Size handling
  const handleBrushSizeChange = (event) => {
    setBrushSize(parseInt(event.target.value, 10));
  };

  // Background Color handling
  const handleBackgroundColor = (color) => {
    setSelectBackgroundColor(color.hex);
    setShowBackgroundColorPicker(false);
  };

  const clear = () => {
    sigCanvas.current.clear();
    setUploadedFile(null); // Clear the uploaded file when signature is cleared
  };

  const save = () => {
    if (uploadedFile) {
      // Case: File was uploaded
      onSave({ signature: uploadedFile }); // Pass uploaded file as an object with the key "signature"
    } else {
      // Case: Signature was drawn
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });
      const file = new File([blob], "digitalSign.png", { type: mimeString });

      onSave({ signature: file }); // Pass the drawn signature as an object with the key "signature"
    }
  };

  // Separate file upload handler
  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      setUploadedFile(file); // Set uploaded file
      console.log(
        `Uploaded File: Name: ${file.name}, Size: ${file.size}, Type: ${file.type}`
      );
    }
  };

  const onSave = (data) => {
    // Log the file passed in the signature key
    console.log("Signature data:", data.signature);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <div className="degital_sign pt-2 pb-4 d-flex flex-column position-relative ">
        <div className="d-flex justify-content-between px-4">
          <div className="d-flex justify-content-start align-items-center gap-3">
            {/* Brush Color Picker */}
            <div className="d-flex position-relative gap-2 align-items-center">
              <p style={{ color: "#42474E" }} className="gilroy m-0">
                Brush
              </p>
              <div
                className="brush__color_picker d-flex align-items-center p-1 gap-2 border"
                onClick={() => setShowColorPicker(!showColorPicker)}
                style={{ cursor: "pointer", backgroundColor: "#EEEDF1" }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: selectedColor,
                  }}
                  className="color_box"
                />
                <ArrowForwardIosRounded sx={{ fontSize: "12px" }} />
              </div>
              {showColorPicker && (
                <div className="color_picker__popover position-absolute ">
                  <CompactPicker
                    color={selectedColor}
                    onChange={handleSelectColor}
                  />
                </div>
              )}
            </div>

            {/* Brush Size Picker */}
            <div className="brush__size_picker d-flex align-items-center">
              <Select
                className="brush_size p-1"
                style={{
                  width: "50px",
                  height: "30px",
                  padding: "2px",
                  backgroundColor: "#EEEDF1",
                  border: "none",
                }}
                value={brushSize}
                onChange={handleBrushSizeChange}
                placeholder=""
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
              </Select>
            </div>

            {/* Background Color Picker */}
            <div className="d-flex position-relative gap-2 align-items-center">
              <p style={{ color: "#42474E" }} className="gilroy m-0">
                Background
              </p>
              <div
                className="brush__color_picker d-flex align-items-center p-1 gap-2 border"
                onClick={() =>
                  setShowBackgroundColorPicker(!showBackgroundColorPicker)
                }
                style={{ cursor: "pointer", backgroundColor: "#EEEDF1" }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: selectBackgroundColor,
                  }}
                  className="color_box"
                />
                <ArrowForwardIosRounded sx={{ fontSize: "12px" }} />
              </div>
              {showBackgroundColorPicker && (
                <div className="color_picker__popover position-absolute ">
                  <CompactPicker
                    color={selectBackgroundColor}
                    onChange={handleBackgroundColor}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Upload Button and Draw Button */}
          <div className="d-flex gap-2">
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={
                <CloudUpload
                  size="small"
                  sx={{ width: "30px", height: "30px" }}
                />
              }
              size="small"
              className="gilroy"
            >
              Upload Sign
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileUpload}
                multiple
              />
            </Button>
            <Button
              variant="outlined"
              sx={{ border: "2px solid", "&:hover": { border: "2px solid" } }}
              className="gilroy fw-bold"
            >
              <FontAwesomeIcon icon={faPaintBrush} fontSize="15px" />
              &nbsp; Draw
            </Button>
          </div>
        </div>

        <div className="h-100 d-flex flex-column justify-content-around align-items-center mt-1 ">
          <div
            style={{ backgroundColor: "#f3f3f3" }}
            className="w-100 d-flex justify-content-center align-items-center"
          >
            <SignatureCanvas
              ref={sigCanvas}
              penColor={selectedColor}
              minWidth={brushSize - 1}
              maxWidth={brushSize}
              canvasProps={{
                width: 500,
                height: 150,
                className: "sigCanvas border",
              }}
              backgroundColor="#f3f3f3"
            />
          </div>
          <div className="d-flex justify-content-start gap-3 w-100 px-4 ">
            <Button
              variant="contained"
              onClick={save}
              sx={{
                backgroundColor: "#FA9B00",
                padding: "5px 20px",
                "&:hover": {
                  backgroundColor: "#de8a00",
                },
              }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={clear}
              sx={{
                backgroundColor: "#C2C7CF",
                padding: "5px 20px",
                "&:hover": {
                  backgroundColor: "#9ea3ab",
                },
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DegitalSign;
