import React, { useState, useEffect } from "react";
import "./Details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWallet,
  faClock,
  faReceipt,
  faWandMagicSparkles,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";
import { Button } from "@mui/material";
import DegitalSign from "./DegitalSign/DegitalSign";
import { ClearRounded } from "@mui/icons-material";

function Details({ data }) {
  const [auctionDetails, setAuctionDetails] = useState([]);

  const [isBidPopupOpen, setIsBidPopupOpen] = useState(false);

  console.log("auctionDetails", data);

  const handlePlaceBidClick = () => {
    setIsBidPopupOpen(true);
  };

  const handleCloseBidPopup = () => {
    setIsBidPopupOpen(false);
  };

  useEffect(() => {
    const response = localStorage.getItem("custAuctionDetails");
    const parsedData = JSON.parse(response);
    if (parsedData) {
      console.log("local", parsedData);
      setAuctionDetails(parsedData[0]);
    }
  }, []);

  return (
    <>
      <div className="auction-all-details px-5 d-flex flex-column align-items-center">
        <div className=" px-5">
          <div className=" my-4">
            <h5 className="details-titles title-name fs-4 fw-bold gilroy mb-0">
              {data.categoryName}
            </h5>
            <p className="all-details fs-5 fw-bold m-0 p-0">
              {data.serviceName}
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faWallet}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles budget fs-4 fw-bold gilroy mb-0">
                Budget
              </h5>
            </div>
            <p className="all-details fs-5 ">{data.budget} Rupees</p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles budget fs-4 fw-bold gilroy mb-0">
                Preferred Date & Time:
              </h5>
            </div>
            <p className="all-details fs-5 ">
              {data.date} | {data.time}
            </p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />

              <h5 className="details-titles mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Address:
              </h5>
            </div>
            <p className="all-details fs-5 ">{data.address}</p>
          </div>
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faClock}
                style={{ color: "#1365af", fontSize: "20px" }}
              />

              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Auction Duration:
              </h5>
            </div>
            <p className="all-details fs-5 ">{data.auction_duration} Days</p>
          </div>
          {/* <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faReceipt}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Package includes:
              </h5>
            </div>
            <ul className="all-details fs-5 ">
              <li>Cobweb removal</li>
              <li>
                Common Pest Coverage (Ants, Cockroaches, Spider & Mice and Rats)
              </li>
              <li>Seasonal Pest Protection (Wasps, mosquitoes etc.)</li>
            </ul>
          </div> */}
          <div className="my-4">
            <div className=" d-flex gap-2 align-items-center">
              <FontAwesomeIcon
                icon={faWandMagicSparkles}
                style={{ color: "#1365af", fontSize: "20px" }}
              />
              <h5 className="details-titles auction-duration mb-0 p-0 budget fs-4 fw-bold gilroy ">
                Special Instructions:
              </h5>
            </div>
            <p className="all-details fs-5 ">{data.instruction}</p>
          </div>
        </div>

        <Button
          fullWidth={false}
          style={{
            backgroundColor: "rgba(250, 155, 0, 1)",
            padding: "8px 15px",
          }}
          className="text-capitalize text-white fs-5"
          onClick={handlePlaceBidClick}
        >
          Place a Bid
        </Button>

        <Popup open={isBidPopupOpen} onClose={handleCloseBidPopup} modal nested>
          <div className="popup_content py-4">
            <div className="popup-content mx-4 d-flex align-items-center gap-2">
              <ClearRounded
                sx={{ width: 30, height: 30 }}
                onClick={handleCloseBidPopup}
              />
              <h2 className="digital_sign__heading gilroy-regular m-0">
                Please sign to confirm your bid!
              </h2>
            </div>
            <DegitalSign />
          </div>
        </Popup>
      </div>
    </>
  );
}

export default Details;
