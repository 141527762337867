import React, { useState } from "react";
import "./MyOrdersPaginate.css";
import { useNavigate } from "react-router-dom";
import orderImage from "../../Rectangle 2057.png";
import { Avatar, Grid, useMediaQuery } from "@mui/material";
import { ordersContent } from "./myOrdersData";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faXmark,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import MyOrdersFilter from "../MyOrdersFilter/MyOrdersFilter";
import MyOrdersSort from "../MyOrderSort/MyOrderSort";

const itemsPerPage = 4;

const MyOrdersPaginate = () => {
  const navigate = useNavigate();

  // useStates ------------------------
  const [currentPage, setCurrentPage] = useState(0);

  // Screen size for responsiveness--------------------
  const isVerySmallScreen = useMediaQuery("(max-width: 426px) ");
  const isSmallScreen = useMediaQuery("(max-width: 576px)");
  // const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const islargeScreen = useMediaQuery("(max-width: 1024px)");
  const isMediumXLargeScreen = useMediaQuery(
    "(min-width: 568px) and (max-width:768px)"
  );

  // Styling -------------------
  const styleLabel = {
    fontSize: isVerySmallScreen ? "12px" : islargeScreen ? "14px" : "16px",
    color: "#343538",
  };
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = ordersContent.slice(offset, offset + itemsPerPage);

  //  Handling Show Bids Button -----------------------------
  const handleOrders = () => {
    navigate("/my-order-page");
  };

  return (
    <div className="mt-5">
      <div className="mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3 align-items-center">
            <MyOrdersFilter />
            <p
              style={{ backgroundColor: "#EEEDF1" }}
              className="m-0 d-none p-1 px-3 rounded-pill text-center"
            >
              <FontAwesomeIcon icon={faXmark} style={{ fontSize: "12px" }} />
              &nbsp;&nbsp; Recent
            </p>
          </div>
          <MyOrdersSort />
        </div>
      </div>

      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div
              onClick={handleOrders}
              className="border my-4 py-2 pe-2 p-sm-4 rounded"
            >
              <Grid container>
                <Grid item sm={12} md={2} className="">
                  <img
                    src={orderImage}
                    alt={item.title}
                    style={{
                      width: islargeScreen ? "8.5rem" : "10rem",
                      height: islargeScreen ? "8.5rem" : "10rem",
                    }}
                  />
                </Grid>

                <Grid item sm={12} md={10}>
                  <div className="ps-4 d-flex flex-column justify-content-between h-100 py-1 ">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <h4 className="order_title gilroy-regular m-0">
                          {item.title}
                        </h4>
                        <p className="order_area__pr_sq_ft gilroy-regular m-0 ">
                          {item.area}
                        </p>
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          style={{ fontSize: "20px", color: "#21272A" }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        <div className="d-flex gap-5">
                          <div className="d-flex flex-column gap-3">
                            <div className="d-flex align-items-center gap-2">
                              <Avatar
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 14, height: 14 }}
                              />
                              <p className="orders_infos__text gilroy m-0">
                                {item.serviceOrdered}
                              </p>
                            </div>
                            <div>
                              <div className="d-flex align-items-center ps-3">
                                <p className="orders_infos__text gilroy m-0 ps-1">
                                  Order ID: {item.orderId}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-3">
                            <div className="d-flex align-items-center gap-2">
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                style={{ color: "#275EA7", fontSize: "16px" }}
                              />
                              <p className="orders_infos__text gilroy m-0">
                                {item.location}
                              </p>
                            </div>

                            <div>
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  style={{
                                    color: "#275EA7",
                                    fontSize: "14.5px",
                                  }}
                                />
                                <p className="orders_infos__text gilroy m-0">
                                  {item.dateTime}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="order_infos__order_status">
                        <p
                          style={{
                            backgroundColor:
                              item.status === "Completed"
                                ? "#E8FBE8"
                                : "#F2EFCF",
                            color:
                              item.status === "Completed"
                                ? "#2A9928"
                                : "#898400",

                            // ------------------------------------
                          }}
                          className="order_status m-0 rounded-pill gilroy"
                        >
                          Status: {item.status}
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(ordersContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </div>
  );
};

export default MyOrdersPaginate;
