import React, { useState, useEffect } from "react";
import "./ServiceCategories.css";
import { ArrowBackRounded } from "@mui/icons-material";
import {
  useMediaQuery,
  Link,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Typography,
} from "@mui/material";
import PickService from "./PickService/PickService";
import RequestDetails from "./RequestDetails/RequestDetails";
import Reviews from "./Reviews/Reviews";
import SuccessMsg from "./SuccessMsg";
import SubCategories from "./SubCategories/SubCategories";
import VirticalStyleLine from "../VirticalStyleLine/VirticalStyleLine";
import { custBidSubCateListAPI, custBidAddAPI } from "../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

const steps = ["Pick a Service", "Request Details", "Reviews"];

const ServiceCategories = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [auctionSuccess, setAuctionSuccess] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCateDetailData, setSubCateDetailData] = useState([]);
  const [childCategoryId, setChildCategoryId] = useState("");
  const [childCategoryName, setChildCategoryName] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceDetails, setServiceDetails] = useState("");
  const [address, setAddress] = useState("");
  const [instruction, setInstruction] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [budget, setBudget] = useState("");
  const [auction_duration, setAuction_duration] = useState("");

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      // The last step (Reviews) where "Post the Auction" is clicked
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);

      if (parsedData && isFormDataComplete()) {
        try {
          const response = await axios.post(custBidAddAPI, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          if (response && response.status === 200) {
            console.log("Bid Add", response);

            toast.success(response.data.msg);
            setAuctionSuccess(true);
          }
        } catch (error) {
          console.error("Error posting auction", error);
          toast.error("Failed to post auction. Please try again.");
        }
      } else {
        toast.error("Please complete all required details.");
      }
    } else if (activeStep === 1) {
      // Move from "Request Details" to "Reviews" without API call
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Move to next step normally
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    window.scroll(0, 0);
  };

  const handleCateId = (data) => {
    console.log("data for bid id", data);
    if (data) {
      const cateId = data;
      setCategoryId(cateId);
    }
  };
  const handleCateName = (data) => {
    console.log("data for bid Name", data);
    if (data) {
      const cateName = data;
      setCategoryName(cateName);
    }
  };

  useEffect(() => {
    const fetchCustBidSubCateListAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData.token && categoryId) {
        try {
          const response = await axios.get(custBidSubCateListAPI + categoryId, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          console.log("cust sub Cate", response);
          if (response.data && response.status === 200) {
            console.log(response.data.data);
            setSubCateDetailData(response.data.data);
          }
        } catch (error) {}
      }
    };
    fetchCustBidSubCateListAPI();
  }, [categoryId]);

  const handlePickService = (data) => {
    setChildCategoryId(data.childCategoryId);
    setChildCategoryName(data.childCategoryName);
    setServiceId(data.serviceId);
    setServiceName(data.serviceName);
    setServiceDetails(data.serviceDetails);
  };

  const handleRequestDetails = (data) => {
    setAddress(data.address);
    setInstruction(data.spcInstruction);
    setDate(data.serviceDate);
    setTime(data.timing);
    setBudget(data.budget);
    setAuction_duration(data.auctionDuration);
  };

  const formData = {
    categoryId: categoryId,
    categoryName,
    childCatId: childCategoryId,
    childCatName: childCategoryName,
    serviceId,
    serviceName,
    address,
    details: serviceDetails,
    instruction,
    date,
    time,
    budget,
    auction_duration,
  };

  const isFormDataComplete = () => {
    // Check if all keys in formData have values
    return Object.values(formData).every((value) => value);
  };

  console.log(formData);

  return (
    <>
      <div className="service--categories__container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-2 mt-sm-1 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy m-0"
            >
              Start A <span style={{ color: "#1365AF" }}>Bidding</span>
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/service-categories"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Service Categories
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>{steps[activeStep]}</span>
            </p>
          </div>
        </div>

        <section>
          <div className="service_category_container d-flex gap-4 mt-5 justify-content-between">
            <aside className="minicategories--container">
              {activeStep === 0 ? (
                <SubCategories catID={handleCateId} catName={handleCateName} />
              ) : (
                <div className="request_details__img rounded-top">
                  <div className="request_details__text d-flex justify-content-center align-items-end">
                    <Typography
                      sx={{
                        color: "#FA9B00",
                        fontSize: "40px",
                        fontWeight: 600,
                        lineHeight: "47.72px",
                      }}
                      className="gilroy ps-4 pb-5 mb-5"
                    >
                      <span style={{ color: "#ffffff" }}>Find the</span> best
                      pest control services!
                    </Typography>
                  </div>
                </div>
              )}
            </aside>
            <VirticalStyleLine />

            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <React.Fragment>
                {activeStep === 0 && (
                  <PickService
                    data={categoryName}
                    details={subCateDetailData}
                    onSave={handlePickService}
                  />
                )}
                {activeStep === 1 && (
                  <RequestDetails
                    name={serviceName}
                    onSave={handleRequestDetails}
                  />
                )}
                {activeStep === 2 && (
                  <>
                    {auctionSuccess && <SuccessMsg />}
                    <Reviews data={formData} />
                  </>
                )}
                <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    style={{
                      backgroundColor: "#1365AF",
                      color: "#ffffff",
                      padding: "10px 30px",
                    }}
                  >
                    {activeStep === steps.length - 1
                      ? "Post the Auction"
                      : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            </Box>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServiceCategories;
